<template>
  <div class="filter">
    <div class="filter-major">
      <h4 class="filter-titles">Majors</h4>
      <select
        name="filter-major-selections"
        id="filter-major-selections"
        v-model="careerTracks"
        multiple
      >
        <option value="" @click="careerTracks = null">All Career Tracks</option>
        <option value="Art & Design">Art & Design</option>
        <option value="Education & Training">Education & Training</option>
        <option value="Finance">Finance</option>
        <option value="Health & Pharmaceuticals">
          Health & Pharmaceuticals
        </option>
        <option value="Information Technology">Information Technology</option>
        <option value="Law & Government">Law & Government</option>
        <option value="Marketing">Marketing</option>
        <option value="Science & Engineering">Science & Engineering</option>
        <option value="Other">Other</option>
      </select>
    </div>
    <div class="filter-gpa">
      <h4 class="filter-titles">GPA Minimum</h4>
      <input class="filter-gpa-input" type="number" v-model="gpa" />
    </div>
    <div class="filter-exp">
      <label class="filter-titles">
        <h4>Had Experience</h4>
        <input type="checkbox" @click="hasExp = !hasExp" />
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="filter-apply">
      <button class="apply-btn" @click="handleApply">
        <h4>Apply Filters</h4>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";

export default {
  emits: ["apply-filters"],
  setup(props, { emit }) {
    const careerTracks = ref(null);
    const gpa = ref(null);
    const hasExp = ref(false);

    const handleApply = () => {
      emit("apply-filters", {
        careerTracks: careerTracks.value,
        gpa: gpa.value,
        hasExp: hasExp.value,
      });
    };

    return { careerTracks, gpa, hasExp, handleApply };
  },
};
</script>

<style>
.filter {
  border: 2px var(--purple) solid;
  border-radius: 1vh;
  padding: 1.5vh 1.5vw;
  margin: 2vh 0;
  display: flex;
  flex-wrap: wrap;
  animation: fadein 0.5s;
  transform: scaleY(1);
  transform-origin: top;
  transition: all 0.25s ease;
  overflow: hidden;
}

.filter-major {
  flex: 0 1 20%;
}

.filter-gpa {
  flex: 0 1 20%;
}

.filter-exp {
  flex: 0 1 40%;
  position: relative;
}

.filter-apply {
  display: flex;
  align-items: center;
  flex: 0 1 20%;
}

.filter-titles {
  margin-bottom: 0.5vh;
}

@media screen and (max-width: 800px) {
  .filter-major,
  .filter-gpa,
  .filter-exp,
  .filter-apply {
    flex: 0 1 50%
  }

  .filter-major h4,
  .filter-gpa h4,
  .filter-exp h4,
  .filter-apply h4 {
    font-size: 95%;
  }
}

#filter-major-selections {
  overflow-y: scroll;
  height: 15vh;
  width: 85%;
  border: 2px var(--purple) solid;
  border-radius: 1vh;
}

#filter-major-selections option {
  padding: 0.3vh 0.5vw;
  font-size: 120%;
}

.filter-gpa-input,
.filter-gpa-input:focus {
  width: 85%;
  border: 2px var(--purple) solid;
  font-size: 110%;
  padding: 0.3vh 0.5vw;
  border-radius: 1vh;
}

.filter-exp label {
  font-weight: bold;
}

.filter-exp input {
  position: absolute;
  cursor: pointer;
  top: 2.75vh;
  left: 0.5vw;
  height: 0;
  width: 0;
}

.filter-exp span {
  top: 3.5vh;
}

.filter-exp input:checked ~ .checkmark {
  background-color: var(--purple);
  color: white;
}

/* Show the indicator (dot/circle) when checked */
.filter-exp input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.filter-exp .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.apply-btn {
  margin: auto;
  height: 5vh;
  padding: 1vh;
  color: var(--purple-l);
  background-color: white;
  border: 2px var(--purple-l) solid;
  border-radius: 1vh;
}

.apply-btn:hover {
  background-color: var(--purple-l);
  color: white;
  border-color: var(--purple-l);
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
</style>
